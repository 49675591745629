<template>
  <div id="ProducerShowsProfile">
    <card><qs-kpi qlik-id="PFeW" class="single-kpi" /></card>
    <card><qs-kpi qlik-id="unPSFm" class="single-kpi" /></card>

    <QsCard :qlik-ids="['gBXd']" />
    <QsCard :qlik-ids="['NCPxZ']" />
  </div>
</template>

<script>
import Card from "@/components/Card";
import QsKpi from "@/components/Qlik/QsKpi";
import QsCard from "@/components/Qlik/QsCard";

export default {
  name: "ProducerShowsProfile",
  components: { Card, QsKpi, QsCard }
};
</script>

<style scoped>
#ProducerShowsProfile {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 125px 500px;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 20px;
}

.single-kpi {
  font-size: 28px;
}
</style>
